//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/pages/portfolios/historical
// Description: Historical asset allocation page.
// Created:     FUB, June 20, 2022
//==============================================================================

import React, { useState, useEffect } from "react"

import { getPortfolioBase, usePortfolioList } from "../../utils/portfolio-list"
import { usePortfolioHistory } from "../../utils/portfolio-data"

import { Page } from "../../components/layout/page"
import { Breadcrumbs } from "../../components/widgets/breadcrumbs"
//import { Link } from "../../components/widgets/link"
import { TableSimple } from "../../components/widgets/table"
import { useMembership } from "../../utils/member-data"
import { Border } from "../../components/widgets/container"
import { SignUpButton } from "../../components/widgets/sign-up-button"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`HISTORICAL: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`HISTORICAL: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
const HistoricalPage = ({ location }) => {
    // http://localhost:8000/portfolios/historical/?pf=tt-all-stars-tr
    const portfolioList = usePortfolioList()

    const [portfolio, setPortfolio] = useState()
    const [portfolioInfo, setPortfolioInfo] = useState()

    useEffect(() => {
        if (!portfolioList) return

        const searchParams = new URLSearchParams(location.search)
        const portfolio = searchParams.get('pf')
        setPortfolio(portfolio)

        const pfInfo = portfolioList
            .filter(i => i.slug === getPortfolioBase(portfolio))
            ?.[0]
        setPortfolioInfo(pfInfo)
    }, [location.search, portfolioList])

    const membership = useMembership()
    const isPremium = portfolioInfo?.tags?.includes("premium")
    const access = isPremium ? membership?.features.premium : membership?.features.basic

    //const portfolioData = usePortfolioData(portfolioInfo?.slug2)
    const portfolioHistory = usePortfolioHistory(portfolio)

    return (
        <Page
            title="Historical Asset Allocation"
            description="Review the historical asset allocation of the tactical portfolio here."
        >

            <h1>{portfolioInfo?.title}: History</h1>
            <Breadcrumbs text={portfolioInfo?.title} to={`/portfolios/${portfolioInfo?.slug}`} />
            <br />

            {access?.hasAccess === true ? (<>
                <Border>
                    This data is provided for information purposes only. It
                    is not meant to be used for trading.
                </Border>
                <TableSimple data={portfolioHistory} />
            </>) : (
                <Border>
                    <p>
                        You can see the asset allocation for
                        this portfolio with our <strong>Premium membership</strong>.
                    </p>
                    <SignUpButton />
                </Border>
            )}
        </Page >
    )
}

export default HistoricalPage

//==============================================================================
// end of file
